import moment from 'moment';

export function formatDateCs(date: Date): string {
  return moment(date).format('DD. MM. YYYY');
}
export function formatTimeCs(date: Date): string {
  return moment(date).format('HH:mm');
}

export function formatDateAndTimeCs(date: Date): string {
  return moment(date).format('DD. MM. YYYY, HH:mm');
}
