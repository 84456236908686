









import { Component, Prop, Vue } from 'vue-property-decorator';

import Step from './Step.vue';
import { PickupFormSections } from '@/models/pickupForm';

@Component({
  components: { Step }
})
export default class ProgressBar extends Vue {
  @Prop({ required: true }) readonly step!: PickupFormSections;

  get isStepOneDone(): boolean {
    return this.step.CHECK_OUTSIDE;
  }
  get isStepTwoDone(): boolean {
    return this.step.CHECK_INSIDE;
  }
}
