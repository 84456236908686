























































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import ClientOnly from 'vue-client-only';
import FileUploadSimple, { UploadIcons } from '@/components/fileUploadSimple.vue';
import {
  getReservationPickupFormPageData,
  PickupForm,
  PickupFormPageData,
  Reservation,
  PickupFormImages,
  sendPickupForm,
  sendPickupFormImage,
  updatePickupForm,
  PickupFormImageKey,
  FormImage
} from '@/services/reservations.service';
import Button from '@/components/ui/Button.vue';
import { formatDateCs } from '@/utils/dateTimeUtils';
import ProgressBar from '../components/pickupForm/navbar/progressBar/ProgressBar.vue';
import { MILEAGE_ACCEPTABLE_RANGE_DIFFERENCE_IN_KM, PickupFormSections } from '../models/pickupForm';
import { MapIcon, CheckCircleIcon, MapPinIcon, PhoneIcon, MailIcon } from 'vue-feather-icons';
import RadioField from '@/components/ui/form/RadioField.vue';
import Tooltip from '@/components/tooltip.vue';
import moment from 'moment';
import { numberWithSpaces } from '@/utils/formatNumber';
import userService from '@/services/user.service';
import { Permissions } from '../models/roles';

@Component({
  components: {
    FileUploadSimple,
    Button,
    ClientOnly,
    ProgressBar,
    MapIcon,
    MapPinIcon,
    PhoneIcon,
    MailIcon,
    CheckCircleIcon,
    RadioField,
    Tooltip
  },
  validations: {
    form: {
      fullTank: { required },
      fullTankOther: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.fullTank === 'no';
        })
      },
      noOutsideDamageOther: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.noOutsideDamage === 'no';
        })
      },
      noInsideDamageOther: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.noInsideDamage === 'no';
        })
      },
      documentsInsideBoxOther: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.documentsInsideBox === 'no';
        })
      }
    }
  },
  filters: {
    numberWithSpaces
  }
})
export default class PickingUpForm extends Vue {
  private form: PickupForm = {
    noOutsideDamage: '',
    noOutsideDamageOther: '',
    noInsideDamage: '',
    noInsideDamageOther: '',
    fullTank: '',
    fullTankOther: '',
    documentsInsideBox: '',
    documentsInsideBoxOther: '',
    comments: '',
    mileage: null,
    outsidePhotos1: null,
    outsidePhotos2: null,
    outsidePhotos3: null,
    outsidePhotos4: null,
    insidePhotos1: null,
    insidePhotos2: null,
    insidePhotos3: null,
    additionalPhotosOutside1: null,
    additionalPhotosOutside2: null,
    additionalPhotosOutside3: null,
    additionalPhotosOutside4: null,
    additionalPhotosInside1: null,
    additionalPhotosInside2: null,
    additionalPhotosInside3: null,
    additionalPhotosInside4: null
  };

  private photosLoading: Record<PickupFormImageKey, boolean> = {
    outsidePhotos1: false,
    outsidePhotos2: false,
    outsidePhotos3: false,
    outsidePhotos4: false,
    insidePhotos1: false,
    insidePhotos2: false,
    insidePhotos3: false,
    additionalPhotosOutside1: false,
    additionalPhotosOutside2: false,
    additionalPhotosOutside3: false,
    additionalPhotosOutside4: false,
    additionalPhotosInside1: false,
    additionalPhotosInside2: false,
    additionalPhotosInside3: false,
    additionalPhotosInside4: false
  };

  pickupFormData: PickupFormPageData | null = null;
  finished = false;
  isLoading = false;
  isOnParking = true;
  hasFullTank = true;
  fuelTankInLitres = 0;
  isFormValid: boolean | null = null;
  reservation: Reservation | null = null;
  uploadIcons = UploadIcons;
  progressBarCompletedSections: PickupFormSections = {
    CHECK_OUTSIDE: false,
    CHECK_INSIDE: false
  };
  mandatoryFieldsCompleted = false;
  showMileageWarning = false; // Control flag for showing the mileage warning
  warningParagraphHeight = 0; // Height of the warning paragraph

  isAdmin = false;

  get reservationId(): string {
    return this.$route.query.id as string;
  }

  get isReservationNow(): boolean {
    return (
      !!this.pickupFormData &&
      moment().isBetween(moment(this.pickupFormData.start).subtract(1, 'hour'), this.pickupFormData.end)
    );
  }

  get formatedDateForToastNotification(): string {
    if (!this.pickupFormData) {
      return '';
    }
    const formatedStartDate = formatDateCs(this.pickupFormData.start);
    const formatedEndDate = formatDateCs(this.pickupFormData.end);

    return formatedStartDate === formatedEndDate ? formatedStartDate : `od ${formatedStartDate} do ${formatedEndDate}`;
  }

  // vue is love https://stackoverflow.com/questions/62729380/vue-watch-outputs-same-oldvalue-and-newvalue
  // to be able watch new vs old value in the object it needs to be recreated each time it changes
  // breaking the reference of object keys (@Watch('form') returns the same old and new values)
  // reasoning behind is updating only changed value.
  get formToBeWatched() {
    return { ...this.form };
  }

  @Watch('formToBeWatched', { deep: true })
  onFormChange(currentForm: PickupForm, oldForm: PickupForm) {
    Object.keys(currentForm).forEach((key) => {
      if (currentForm[key] !== oldForm[key] && typeof currentForm[key] === 'string') {
        localStorage.setItem(`pickup-${key}`, currentForm[key] as string);
      }
    });
  }

  public showNotificationForNavigationToCarDisabled(): void {
    if (!this.isReservationNow && this.pickupFormData) {
      this.$toast.error(
        `Přesnou polohu dodávky uvidíte pouze v termínu výpůjčky, tedy ${this.formatedDateForToastNotification}.`
      );
    }
  }

  get sectionsCompleted(): PickupFormSections {
    this.progressBarCompletedSections.CHECK_OUTSIDE = !!(
      this.form.outsidePhotos1 &&
      this.form.outsidePhotos2 &&
      this.form.outsidePhotos3 &&
      this.form.outsidePhotos4 &&
      this.form.insidePhotos3 &&
      (this.form.noOutsideDamage === 'yes' || this.form.noOutsideDamageOther)
    );
    this.progressBarCompletedSections.CHECK_INSIDE = !!(
      this.form.insidePhotos1 &&
      this.form.mileage &&
      this.form.insidePhotos2 &&
      (this.form.fullTank === 'yes' || this.form.fullTankOther) &&
      (this.form.noInsideDamage === 'yes' || this.form.noInsideDamageOther) &&
      (this.form.documentsInsideBox === 'yes' || this.form.documentsInsideBoxOther)
    );
    this.mandatoryFieldsCompleted =
      !!(this.form.fullTank === 'yes' || this.form.fullTankOther) &&
      (this.form.noInsideDamage === 'no' ? !!this.form.noInsideDamageOther : true) &&
      (this.form.noOutsideDamage === 'no' ? !!this.form.noOutsideDamageOther : true) &&
      (this.form.documentsInsideBox === 'no' ? !!this.form.documentsInsideBoxOther : true);

    return this.progressBarCompletedSections;
  }

  get useUpdatePickupForm(): boolean {
    if (!this.pickupFormData || !this.pickupFormData.pickupForm) {
      return false;
    }
    return this.mandatoryPickupFormDataFilledOut(this.pickupFormData.pickupForm) && this.isAdmin;
  }

  get formatedStartDate(): string {
    if (!this.pickupFormData) {
      return '';
    }
    const date = this.pickupFormData.start;

    return `vypůjčeno od ${formatDateCs(date)}`;
  }

  getDocumentPath(existingImage: FormImage): string | null {
    return existingImage && typeof existingImage.path === 'string' ? existingImage.path : null;
  }

  getImagePreviewUrl(key: keyof PickupForm): string | null {
    if (this.form[key] && typeof this.form[key] === 'string') {
      return this.form[key] as string;
    }
    return null;
  }

  async mounted() {
    await new Promise<void>((resolve) => {
      this.$watch('$store.state.fetchingLoggedUser', (newVal) => {
        if (!newVal) {
          // When logged user stops being fetched (=value updates)
          resolve();
        }
      });
    });

    this.isAdmin = await userService.isAllowedTo(Permissions.reservationReturnformManage);
    this.checkLocalStorage();
    this.populateFormFromLocalStorage();
    if (!this.reservationId) {
      this.$router.push('/');
      return;
    }
    this.getReservationAndCarData();
  }

  private async getReservationAndCarData() {
    try {
      await this.getReservationPickupFormData();
      if (!this.pickupFormData) {
        throw new Error('Car id is not provided');
      }
    } catch (err) {
      console.error(err);
      return;
    }
  }

  private checkLocalStorage() {
    if (this.reservationId !== localStorage.getItem('pickup-lastReservationId')) {
      this.pruneLocalStorage();
      localStorage.setItem('pickup-lastReservationId', this.reservationId);
    }
  }
  private populateFormFromLocalStorage() {
    if (this.form) {
      Object.keys(this.form).forEach((key) => {
        const value = localStorage.getItem(`pickup-${key}`);
        if (value) {
          this.form[key] = value;
        }
      });
    }
  }

  private pruneLocalStorage() {
    Object.keys(this.form).forEach((key) => {
      localStorage.removeItem(`pickup-${key}`);
    });
  }

  private async getReservationPickupFormData() {
    try {
      this.pickupFormData = await getReservationPickupFormPageData(this.reservationId);
      if (!this.pickupFormData.pickupForm) {
        this.pickupFormData.pickupForm = this.form;
      }
      if (
        this.pickupFormData.pickupForm &&
        (this.isAdmin || !this.mandatoryPickupFormDataFilledOut(this.pickupFormData.pickupForm))
      ) {
        // Temporary Fix Pickup Form
        /* if (this.pickupFormData.fuelTankInLitres) {
           this.fuelTankInLitres = this.pickupFormData.fuelTankInLitres;
           //5% tolerance
           const ERROR_TOLERANCE = 1.05;
           this.hasFullTank =
            this.pickupFormData.fuelTankInLitres >= this.pickupFormData.fuelTankCapacityInLitres / ERROR_TOLERANCE;
          if (this.pickupFormData.pickupForm.fullTank === '') {
            // this.pickupFormData.pickupForm.fullTank = !this.hasFullTank ? 'no' : '';
            this.pickupFormData.pickupForm.fullTank = !this.hasFullTank ? 'no' : '';
          }
        } */
        this.form = { ...this.form, ...this.pickupFormData.pickupForm };
      }
      this.hasFullTank = true;
      if (!this.isAdmin && this.mandatoryPickupFormDataFilledOut(this.pickupFormData.pickupForm)) {
        this.$toast.error('Formulář nelze vyplnit vícekrát');
        this.$router.push('/');
      }
    } catch (err) {
      this.$toast.error((err as Error).message);
      this.$router.push('/');
    }
  }

  async submit() {
    if (!this.useNewMileage && this.pickupFormData) {
      this.form.mileage = this.pickupFormData.carLastKnownMileage;
    }
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        this.isLoading = true;
        if (this.useUpdatePickupForm) {
          await updatePickupForm(this.reservationId, this.form);
        } else {
          await sendPickupForm(this.reservationId, this.form, this.fuelTankInLitres);
        }
        this.isLoading = false;
        this.pruneLocalStorage();
        this.finished = true;
        window.scrollTo(0, 0);
      } catch (e) {
        this.isLoading = false;
        this.$toast.error((e as Error).message);
      }
    }
  }

  async uploadImage(image: PickupFormImages) {
    if (Object.values(image).every((val) => val !== null)) {
      const key = Object.keys(image)[0] as PickupFormImageKey;
      this.photosLoading[key] = true;
      try {
        const res = await sendPickupFormImage(this.reservationId, image);
        this.form = {
          ...this.form,
          ...res
        };
        if (this.pickupFormData) {
          this.pickupFormData.pickupForm = {
            ...this.pickupFormData.pickupForm,
            ...res
          };
        }
      } catch (err) {
        this.$toast.error((err as Error).message);
        console.error(err);
      }
      this.photosLoading[key] = false;
    }
  }

  private mandatoryPickupFormDataFilledOut(pickupForm: PickupForm): boolean {
    return !!pickupForm && this.mandatoryFieldsCompleted;
  }

  // Determine if new mileage should be used
  get useNewMileage(): boolean {
    // For newly added cars that don't have any mileage (past RForm filled in) yet
    if (this.pickupFormData && !this.pickupFormData.carLastKnownMileage) {
      return true;
    }
    return (
      !!this.form.mileage &&
      !!this.pickupFormData &&
      !!this.pickupFormData.carLastKnownMileage &&
      // Determine if the input mileage is within an acceptable range:
      // If difference between the input mileage and the last known car mileage is <= 10k km
      Math.abs(this.form.mileage - this.pickupFormData.carLastKnownMileage) <= MILEAGE_ACCEPTABLE_RANGE_DIFFERENCE_IN_KM
    );
  }

  // Event handler: Triggered when mileage input loses focus
  public handleBlur() {
    this.showMileageWarning = !this.useNewMileage;
  }
  // Event handler: Triggered when mileage input gains focus
  public handleFocus() {
    this.showMileageWarning = false;
  }
  // Event handler: Triggered on mileage input value change
  public handleInput() {
    this.showMileageWarning = false;
  }

  @Watch('showMileageWarning')
  onShowMileageWarningChange() {
    this.updateWarningParagraphHeight();
  }

  // Update warning paragraph height
  updateWarningParagraphHeight() {
    this.$nextTick(() => {
      const paragraphElement = this.$refs.paragraph as HTMLElement;
      this.warningParagraphHeight = paragraphElement ? paragraphElement.offsetHeight : 0;
    });
  }

  // Get Dynamic paragraph height style value
  get dynamicWarningParagraphHeight() {
    return this.showMileageWarning ? 'none' : this.warningParagraphHeight + 'px';
  }
}
