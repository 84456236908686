export type PickupFormSections = {
  CHECK_OUTSIDE: boolean;
  CHECK_INSIDE: boolean;
};

export interface ParkingPickupForm {
  parkingLocation: {
    lat: number;
    lng: number;
  };
  locationDescription: string;
}

export const MILEAGE_ACCEPTABLE_RANGE_DIFFERENCE_IN_KM = 10000;
