
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { CheckCircleIcon } from 'vue-feather-icons';

@Component({
  components: {
    CheckCircleIcon
  }
})
export default class Step extends Vue {
  @Prop({ required: true }) readonly done!: boolean;
  @Prop({ required: false }) readonly active!: boolean;
  @Prop({ required: true }) readonly title!: string;
}
