




































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FileUploadMixin } from '@/mixins/fileUpload/FileUpload.mixin';
import Config from './../config';

export enum UploadIcons {
  vanBack = 'van-back',
  vanFront = 'van-front',
  vanDashboard = 'van-dashboard',
  vanLeftSide = 'van-left-side',
  vanRightSide = 'van-right-side',
  vanCabin = 'van-cabin',
  vanTrunk = 'van-trunk',
  camera = 'camera'
}

@Component({})
export default class FileUpload extends mixins(FileUploadMixin) {
  @Prop({ required: true, default: null }) readonly reservationId!: string;
  @Prop(Boolean) hasError?: boolean;
  @Prop(String) uploadIcon?: UploadIcons;

  public dragging = false;

  mounted() {
    this.loadReturnFormImage();
  }

  @Watch('documentPath')
  loadReturnFormImage() {
    if (this.documentPath) {
      this.url =
        Config.getValue('backendUrl') +
        `/document/content?path=${this.documentPath}&reservationId=${this.reservationId}`;
    }
  }
  get filePreviewStyle() {
    if (this.documentPath) {
      return {
        'background-color': '#efefef',
        'background-size': 'contain'
      };
    }
  }

  async onChange(e: Event) {
    this.loading = true;
    // @ts-ignore
    const file = e.target.files[0];

    if (!file) {
      this.resetUploadingState();
      return;
    }

    if (!file.name) {
      this.$toast.error('Název souboru je neplatný.');
      this.resetUploadingState();
      return;
    }

    if (!this.isSupportedFile(file.name)) {
      this.$toast.error('Vyberte prosím obrázek ve správném formátu (JPG, PNG nebo HEIC)');
      this.resetUploadingState();
      return;
    }

    const validatedFile = await this.validateFileSize(file);
    if (!validatedFile) {
      this.resetUploadingState();
      return;
    }
    this.setUrl(file);
    this.uploadIcon = undefined;
    this.dragging = false;
    this.$emit('input', validatedFile);
  }

  resetUploadingState() {
    this.loading = false;
    this.dragging = false;
  }

  isSupportedFile(filename: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'heic'];
    return imageExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
  }
}
