




















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class RadioField extends Vue {
  @Prop({ required: true }) readonly value!: string;
  @Prop({ required: true, type: String }) readonly name!: string;
  @Prop({ required: true, type: Boolean }) readonly checked!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) readonly disabled!: boolean;
}
